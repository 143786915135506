import React, { useEffect, useState } from 'react';
import { Message } from './Message.js';
import { CButton, CRow, CCol, CImg } from '@coreui/react';
import { callTokenApi } from '../../../../apiCaller.js';
import { API_CLIENT_ACCOUNT_COMPLETED_SETUP_GUIDE } from '../../../../constants/apiRest.js';
import { useDispatch, useSelector } from 'react-redux';
import { setParentSetupGuideDataCompleted } from '../../../../actions/subscriber.js';
import BoxFeature from './BoxFeature.js';
export const LearnAboutSendingData = (props) => {
     const dispatch = useDispatch();
     const { nextTour, dataFeature, dataStepCompleted } = props;
     const [featureSuccess, setFeatureSuccess] = useState(false);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const numberStepCompleted = dataFeature.feature.filter((feature) => feature.status);
     // const numberSecondStepCompleted = dataFeature.featureSecond ? dataFeature.featureSecond.filter(feature => feature.status) : [];
     // const accountDestination = useSelector((state) => state.subscriber.activeDestinations);
     // const result = accountDestination.filter((des) => des.name !== 'Google Tag Manager');

     const showAnimationRoadmap = localStorage.getItem('showAnimationRoadmap');

     const notShowStepAnimation = localStorage.getItem('sendingData');

     useEffect(() => {
          setTimeout(() => {
               localStorage.setItem('sendingData', true);
               localStorage.removeItem('showAnimationRoadmap');
          }, 2000);
     }, []); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          // if ((numberStepCompleted.length + numberSecondStepCompleted.length) === (dataFeature.feature.length + dataFeature.featureSecond.length)) {
          //      setFeatureSuccess(true);
          // }
          if (numberStepCompleted.length === dataFeature.feature.length) {
               setFeatureSuccess(true);
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [dataFeature]);
     const onClickNextStep = () => {
          // if (result.length > 0) {
          //      nextTour();
          // } else {
          //      nextTour(2);
          // }
          localStorage.setItem('sendingData', true);
          localStorage.setItem('showAnimationRoadmap', true);
          nextTour();
          const data = {
               accountId: activeAccount.id,
               data: { sendingData: true },
          };
          callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_SETUP_GUIDE, 'PUT', data).then((response) => {
               if (response.status === 200) {
                    dispatch(setParentSetupGuideDataCompleted({ sendingData: true }));
               }
          });
     };

     const completeStep = dataStepCompleted ? (dataStepCompleted.completedSteps ? dataStepCompleted.completedSteps.sendingData : false) : false;

     const isShowAnimation = !!(!notShowStepAnimation && !completeStep && showAnimationRoadmap);

     return (
          <CRow className='guide-row'>
               <CCol md='1'>
                    <CImg
                         src='../assets/images/avtAdSetupGuide.png'
                         height='60'
                         width='60'
                         className={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-1s' : ''}
                    />
               </CCol>
               <CCol md='11'>
                    <div className={`tour-guide show setup-guide`}>
                         <div className='tour-guide-content content'>
                              <Message
                                   text={"Okay, it's time to learn about sending data."}
                                   hasLoading={isShowAnimation}
                                   animationDots={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-1s animate__faster-2s' : ''}
                                   animationMessage={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-4s' : ''}
                              />
                              <Message
                                   text={
                                        'Take a few minutes to learn about how we send data to destinations and how you can use ListenLayer to comply with Regulations'
                                   }
                                   hasLoading={isShowAnimation}
                                   animationDots={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-5s animate__faster-3s' : ''}
                                   animationMessage={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-9s' : ''}
                              />

                              <div className={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-9s' : ''}>
                                   {dataFeature.feature.map((feature, index) => {
                                        const offsetStatus =
                                             index > 0 ? dataFeature.feature[index - 1].status : dataFeature.feature[index].status;

                                        return (
                                             <React.Fragment key={index + feature.title}>
                                                  <BoxFeature feature={feature} isActiveConversion={offsetStatus} />
                                                  {/* {feature.status && feature.subContent && (
                                                       <>
                                                            {feature.subContent.map((sub) => (
                                                                 <Message text={sub.text} />
                                                            ))}
                                                       </>
                                                  )} */}
                                             </React.Fragment>
                                        );
                                   })}
                              </div>
                              {/* {numberStepCompleted.length === dataFeature.feature.length && (
                                   <>
                                        {dataFeature.featureSecond.map((feature, index) => {
                                             const offsetStatus = index > 0 ? dataFeature.feature[index - 1].status : dataFeature.feature[index].status;

                                             return <BoxFeature key={feature.title} feature={feature} isActiveConversion={offsetStatus} />;
                                        })}
                                   </>
                              )} */}
                              {featureSuccess && (
                                   <>
                                        <Message
                                             text={"Nice work! Select NEXT when you're ready to move forward."}
                                             hasLoading={isShowAnimation}
                                             animationDots={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-10s' : ''}
                                             animationMessage={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-12s' : ''}
                                        />
                                        <div
                                             className={`buttons justify-content-end ${
                                                  isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-13s' : ''
                                             }`}
                                        >
                                             <CButton className='next-button roadmap' onClick={() => onClickNextStep()}>
                                                  Next
                                             </CButton>
                                        </div>
                                   </>
                              )}
                         </div>
                    </div>
               </CCol>
          </CRow>
     );
};
