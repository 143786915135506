import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import 'animate.css';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import './scss/style.scss';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import cookie from 'js-cookie';

import TheLayout from './containers';
import { AUTH_PATH } from './constants';
import { CSpinner, CRow, CCol, CImg } from '@coreui/react';
import 'react-calendar/dist/Calendar.css';
import { getHostName, initializeGTM } from './utils';
import CreateAccount from './components/cms/subscriber/account/create-account/CreateAccount';

dayjs.extend(utc);
dayjs.extend(timezone);

const Login = React.lazy(() => import('./components/auth/Login'));
const GoogleLoginRedirect = React.lazy(() => import('./components/auth/GoogleLoginRedirect'));
// const Register = React.lazy(() => import('./components/auth/Register'));
const Activate = React.lazy(() => import('./components/auth/Activate'));
const CreateManager = React.lazy(() => import('./components/auth/CreateManager'));
const PasswordForgot = React.lazy(() => import('./components/auth/PasswordForgot'));
const PasswordReset = React.lazy(() => import('./components/auth/PasswordReset'));
const Unsubscribe = React.lazy(() => import('./components/auth/Unsubscribe'));
const InstallationScreen = React.lazy(() => import('./components/auth/InstallationScreen'));
const WelcomeScreen = React.lazy(() => import('./components/auth/WelcomeScreen'));
const Certificate = React.lazy(() => import('./components/auth/Certificate'));
const Invoice = React.lazy(() => import('./components/auth/Invoice'));
const InvoiceUpcoming = React.lazy(() => import('./components/auth/InvoiceUpcoming'));
const ScheduleDemo = React.lazy(() => import('./components/auth/ScheduleDemo'));

const loading = () => (
     <div className="page-loading-container">
          <CSpinner color="primary" />
     </div>
);

const StaticMaintenancePage = () => {
     return (
          <CRow className="update-box">
               <CCol md="6" className="text-right">
                    <CImg src="../assets/images/119232-updating.png" height="19" width="19" />
               </CCol>
               <CCol md="6">
                    <div className="update-box-content d-flex align-items-center">
                         <div className="content">
                              <h1>NEW UPDATE</h1>
                              <h2>We&rsquo;ll be back soon!</h2>
                              <div>
                                   <p>
                                        Sorry for the inconvenience. We&rsquo;re performing some maintenance at the moment. We&rsquo;ll be back up
                                        shortly!
                                   </p>
                                   <p className="text-right">&mdash; The ListenLayer Team &mdash;</p>
                              </div>
                         </div>
                    </div>
               </CCol>
          </CRow>
     );
};

const App = () => {
     const hostname = getHostName();
     const token = cookie.get('token');
     const preventMaintenanceStatus = cookie.get('preventMaintenanceStatus') || true;

     if (!token) {
          initializeGTM();
     }

     const Head = () => (
          <Helmet>
               <meta charSet="utf-8" />
               <title>ListenLayer™ | Cloud Data Layer Platform</title>
               <meta property="og:title" content="ListenLayer™ | Cloud Data Layer Platform" />
               <meta property="og:image" content={`${hostname}main-logo.png`} />
               <meta property="og:description" content="ListenLayer™ | Cloud Data Layer Platform" />
               <meta property="og:url" content={hostname} />
               {/* <link rel='canonical' href={hostname} /> */}
               <link rel="stylesheet" type="text/css" href="https://unpkg.com/@progress/kendo-theme-default/dist/all.css" />
               <link
                    rel="stylesheet"
                    href="https://pro.fontawesome.com/releases/v5.14.0/css/all.css"
                    integrity="sha384-VhBcF/php0Z/P5ZxlxaEx1GwqTQVIBu4G4giRWxTKOCjTxsPFETUDdVL5B6vYvOt"
                    crossorigin="anonymous"
               ></link>
               <link
                    href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400&display=swap"
                    rel="stylesheet"
               ></link>
               <link
                    href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
                    rel="stylesheet"
               />
          </Helmet>
     );

     return (
          <BrowserRouter>
               <Head />
               <React.Suspense fallback={loading()}>
                    {preventMaintenanceStatus ? (
                         <Switch>
                              <Route exact path={AUTH_PATH.LOGIN} name="Login Page" render={(props) => <Login {...props} />} />
                              <Route
                                   exact
                                   path={AUTH_PATH.GOOGLE_LOGIN_REDIRECT}
                                   name="Google Login Redirect Page"
                                   render={(props) => <GoogleLoginRedirect {...props} />}
                              />
                              <Route exact path={AUTH_PATH.REGISTER} name="Register Page" render={(props) => <CreateAccount {...props} />} />
                              <Route exact path={AUTH_PATH.ACTIVATION} render={(props) => <Activate {...props} />} />
                              <Route exact path={AUTH_PATH.INVITE_USER} render={(props) => <CreateManager {...props} />} />
                              <Route exact path={AUTH_PATH.PASSWORD_FORGOT} render={(props) => <PasswordForgot {...props} />} />
                              <Route exact path={AUTH_PATH.PASSWORD_RESET} render={(props) => <PasswordReset {...props} />} />
                              <Route path={AUTH_PATH.UNSUBSCRIBE} render={(props) => <Unsubscribe {...props} />} />
                              <Route exact path={AUTH_PATH.INSTALLATION_SCREEN} render={(props) => <InstallationScreen {...props} />} />
                              <Route exact path={AUTH_PATH.WELCOME_SCREEN} render={(props) => <WelcomeScreen {...props} />} />
                              <Route exact path={AUTH_PATH.CERTIFICATE} render={(props) => <Certificate {...props} />} />
                              <Route exact path={AUTH_PATH.INVOICE} render={(props) => <Invoice {...props} />} />
                              <Route exact path={AUTH_PATH.INVOICE_UPCOMING} render={(props) => <InvoiceUpcoming {...props} />} />
                              <Route exact path={AUTH_PATH.SCHEDULE_DEMO} render={(props) => <ScheduleDemo {...props} />} />
                              <Route path="/:secondId" name="Home" render={(props) => <TheLayout {...props} />} />
                              <Route path="/" name="Home" render={(props) => <TheLayout {...props} />} />
                         </Switch>
                    ) : (
                         <StaticMaintenancePage />
                    )}
                    {/* Make toast function of react-toastify work in all components */}
                    <ToastContainer />
               </React.Suspense>
          </BrowserRouter>
     );
};

export default App;
