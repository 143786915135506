import { CButton, CCard, CCardBody } from '@coreui/react';
import React, { useContext } from 'react';
import { DESTINATION_CODE, SWITCH_TYPE_WIZARD_STEP } from '../../../../../../constants';
import { useActiveDestination } from '../../../../../../helpers/customHooks';
import Waiting from '../../../../../general/Loadings/Waiting';
import { SwitchTypeWizardBody, SwitchTypeWizardContext } from '../SwitchTypeWizard';
import RequiredValueInput from './RequireValueInput';

const GetStarted = () => {
     const { setCurrentStep, setShowCancelPopup, closeWizard, handleUpdateSwitchType, isGTM, destinationName } = useContext(SwitchTypeWizardContext);
     const activeDestination = useActiveDestination();
     const { generalSettings } = activeDestination;
     const requireFields = [];

     if (!isGTM) {
          switch (activeDestination.code) {
               case DESTINATION_CODE.GA4:
                    if (!generalSettings) {
                         requireFields.push('a Measurement ID', 'an API Secret');
                    } else {
                         if (!generalSettings.measurementId) {
                              requireFields.push('a Measurement ID');
                         }
                         if (!generalSettings.apiSecret) {
                              requireFields.push('an API Secret');
                         }
                    }
                    break;
               case DESTINATION_CODE.FACEBOOK_ADS:
                    if (!generalSettings) {
                         requireFields.push('a Pixel ID', 'an Access Code');
                    } else {
                         if (!generalSettings.pixelId) {
                              requireFields.push('a Pixel ID');
                         }
                         if (!generalSettings.accessToken) {
                              requireFields.push('an Access Code');
                         }
                    }
                    break;
               default:
                    break;
          }
     }

     const goToNextStep = () => {
          setCurrentStep(SWITCH_TYPE_WIZARD_STEP.migrate);
     };

     const onSubmitStartButton = (e) => {
          e.preventDefault();
          goToNextStep();
          handleUpdateSwitchType({ initStep: SWITCH_TYPE_WIZARD_STEP.migrate });
     };

     return (
          <SwitchTypeWizardBody onBackBtnClicked={() => closeWizard()}>
               {requireFields.length > 0 ? (
                    <RequiredValueInput requireFields={requireFields} />
               ) : (
                    <CCard className='get-started'>
                         <CCardBody>
                              <h3>Switch to {isGTM ? 'Client' : 'Server'} Side Tagging</h3>
                              <p className='des'>
                                   Configuring ListenLayer to send Conversions and other events to {destinationName} using {isGTM ? 'Client' : 'Server'} Side Tagging.
                              </p>
                              {isGTM && (
                                   <span className='foot-note mb-4'>
                                        We don't recommend switching from Server Side to client-side tagging, but if this is something you need, we can walk through the
                                        process.
                                   </span>
                              )}
                              <span className='foot-note mb-1 text-justify'>During this process, you'll complete the following:</span>
                              <div className='changes-box'>
                                   <div className='change-item'>
                                        <p>1. Migrate your existing Data Actions to {isGTM ? 'client' : 'server'} side.</p>
                                   </div>
                                   <div className='change-item'>
                                        <p>
                                             2.{' '}
                                             {isGTM
                                                  ? `Distribute your changes to GTM (you'll need publishing rights)`
                                                  : `Clean up your GTM implementation to remove duplicates (optional)`}{' '}
                                        </p>
                                   </div>
                              </div>
                              <span className='foot-note px-3'>
                                   This process can take anywhere from 2 to 5 minutes. You'll have a chance to review and cancel changes.
                              </span>
                              <CButton className='btn-save' type='submit' style={{ width: '40%' }} onClick={onSubmitStartButton}>
                                   <Waiting>LET'S START</Waiting>
                              </CButton>
                              <CButton className='btn-navigation-step back' onClick={() => setShowCancelPopup(true)}>
                                   No thanks, go back
                              </CButton>
                         </CCardBody>
                    </CCard>
               )}
          </SwitchTypeWizardBody>
     );
};

export default GetStarted;
