import React, { useContext } from 'react';
import { CButton, CCard, CCardBody } from '@coreui/react';
import { SwitchTypeWizardBody, SwitchTypeWizardContext } from '../SwitchTypeWizard';

const Finally = () => {
     const { closeWizard, isGTM, destinationName } = useContext(SwitchTypeWizardContext);

     return (
          <SwitchTypeWizardBody shouldHideBackBtn={true}>
               <CCard className='finally'>
                    <CCardBody>
                         <h3>Your Changes Have Been Published</h3>
                         <p className='des'>
                              {destinationName} is now running {isGTM ? 'Client' : 'Server'} Side! You can close this wizard.
                         </p>
                         <CButton className='btn-save' type='submit' style={{ width: '40%' }} onClick={closeWizard}>
                              CLOSE
                         </CButton>
                    </CCardBody>
               </CCard>
          </SwitchTypeWizardBody>
     );
};

export default Finally;
