import { CCard, CCardBody } from '@coreui/react';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSubscriberState } from '../../../../../../actions/subscriber';
import { callTokenApi } from '../../../../../../apiCaller';
import llConnectToGTMAnimationData from '../../../../../../assets/lottie-files/ll-connect-to-gtm.json';
import {
     API_CLIENT_DESTINATIONS_GTM_CHANGES_SWITCH_CLIENT_TO_SERVER,
     API_CLIENT_DESTINATIONS_GTM_CHANGES_SWITCH_SERVER_TO_CLIENT,
     GTM_ACTIONS,
     SWITCH_TYPE_WIZARD_STEP,
} from '../../../../../../constants';
import { useActiveDestination, useCancelToken, useHasPublishGTMPermission } from '../../../../../../helpers/customHooks';
import CommonLottie from '../../../../../general/lottie/CommonLottie';
import { SwitchTypeWizardBody, SwitchTypeWizardContext } from '../SwitchTypeWizard';

const GTMMakeChanges = () => {
     const dispatch = useDispatch();
     const { stepsData, setStepsData, setCurrentStep, handleReloadData, handleUpdateSwitchType, isGTM, objectsData } = useContext(SwitchTypeWizardContext);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);

     const { gtmHasUnpublishedChanges, workspaceName, workspacePath, doPublish, gtmHasLLWorkspace  } = stepsData;
     const getCancelToken = useCancelToken();
     const activeDestination = useActiveDestination();
     const { currentContainer } = activeAccount.gtmInfo;
     const hasPublishPermission = useHasPublishGTMPermission();

     const handleSuccess = async () => {
          // Refresh data
          handleReloadData();

          if (!hasPublishPermission && doPublish) {
               setCurrentStep(SWITCH_TYPE_WIZARD_STEP.gtmNoPublishingRights);
               return;
          }

          setStepsData({ gtmChanged: true });
          setCurrentStep(SWITCH_TYPE_WIZARD_STEP.gtmSuccess);
          handleUpdateSwitchType({ initStep: SWITCH_TYPE_WIZARD_STEP.getStarted }); // Reset step
     };

     // Client -> Server
     const handleSwitchClientToServer = () => {
          const postData = {
               accountId: activeAccount.id,
               container: {
                    containerId: currentContainer.containerId,
                    path: currentContainer.path,
                    publicId: currentContainer.publicId,
               },
               action: GTM_ACTIONS.MAKE_CHANGES,
               gtmHasUnpublishedChanges,
               workspaceName,
               workspacePath,
               hasPublishPermission,
               doPublish,
               gtmHasLLWorkspace,
               destinationId: activeDestination.id,
          };

          callTokenApi(API_CLIENT_DESTINATIONS_GTM_CHANGES_SWITCH_CLIENT_TO_SERVER, 'POST', postData, getCancelToken()).then((response) => {
               if (response) {
                    dispatch(setSubscriberState({ gtmVariablesInLL: null, gtmTriggersInLL: null, gtmTagsInLL: null, gtmChangelog: null }));

                    if (response.status === 200) {
                         handleSuccess();
                    } else {
                         if (response.data.isRunOutOfWorkspace) {
                              setStepsData({ isRunOutOfWorkspace: true });
                         }

                         if (response.data.publishFailed) {
                              handleSuccess();
                              setStepsData({ publishFailed: true });
                         }

                         if (response.data.duplicateTriggerName) {
                              setStepsData({ duplicateTriggerName: response.data.duplicateTriggerName });
                         }
                         if (response.data.duplicateTagName) {
                              setStepsData({ duplicateTagName: response.data.duplicateTagName });
                         }

                         setCurrentStep(SWITCH_TYPE_WIZARD_STEP.gtmUnknownFailure);
                    }
               }
          });
     };

     // Server -> Client
     const handleSwitchServerToClient = () => {
          const postData = {
               accountId: activeAccount.id,
               container: {
                    containerId: currentContainer.containerId,
                    path: currentContainer.path,
                    publicId: currentContainer.publicId,
               },
               action: GTM_ACTIONS.MAKE_CHANGES,
               gtmHasUnpublishedChanges,
               workspaceName,
               workspacePath,
               hasPublishPermission,
               doPublish,
               gtmHasLLWorkspace,
               destinationId: activeDestination.id,
               accountDestinationId: activeDestination.accountDestinationId,
               objectsData,
          };

          callTokenApi(API_CLIENT_DESTINATIONS_GTM_CHANGES_SWITCH_SERVER_TO_CLIENT, 'POST', postData, getCancelToken()).then((response) => {
               if (response) {
                    dispatch(setSubscriberState({ gtmVariablesInLL: null, gtmTriggersInLL: null, gtmTagsInLL: null, gtmChangelog: null }));

                    if (response.status === 200) {
                         handleSuccess();
                    } else {
                         if (response.data.isRunOutOfWorkspace) {
                              setStepsData({ isRunOutOfWorkspace: true });
                         }

                         if (response.data.publishFailed) {
                              handleSuccess();
                              setStepsData({ publishFailed: true });
                         }

                         if (response.data.duplicateTriggerName) {
                              setStepsData({ duplicateTriggerName: response.data.duplicateTriggerName });
                         }
                         if (response.data.duplicateTagName) {
                              setStepsData({ duplicateTagName: response.data.duplicateTagName });
                         }

                         setCurrentStep(SWITCH_TYPE_WIZARD_STEP.gtmUnknownFailure);
                    }
               }
          });
     };

     const handleFirstLoad = () => {
          if (isGTM) {
               handleSwitchServerToClient(); // Server -> Client
          } else {
               handleSwitchClientToServer(); // Client -> Server
          }
     };
     useEffect(handleFirstLoad, []);

     return (
          <SwitchTypeWizardBody>
               <CCard className='account-gtm making-changes'>
                    <CCardBody>
                         <h3>Making changes in GTM</h3>
                         <CommonLottie animationData={llConnectToGTMAnimationData} />
                         <p>We're working on making these changes; please wait on this screen (it will only take a few seconds).</p>
                    </CCardBody>
               </CCard>
          </SwitchTypeWizardBody>
     );
};

export default GTMMakeChanges;
