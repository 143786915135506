import { CButton, CCard, CCardBody } from '@coreui/react';
import React, { useContext } from 'react';
import { SWITCH_TYPE_WIZARD_STEP } from '../../../../../../constants';
import Waiting from '../../../../../general/Loadings/Waiting';
import { SwitchTypeWizardBody, SwitchTypeWizardContext } from '../SwitchTypeWizard';

const Migrate = () => {
     const { setCurrentStep, handleUpdateSwitchType, isGTM } = useContext(SwitchTypeWizardContext);

     const onBackBtnClicked = () => {
          setCurrentStep(SWITCH_TYPE_WIZARD_STEP.getStarted);
          handleUpdateSwitchType({ initStep: SWITCH_TYPE_WIZARD_STEP.getStarted });
     };

     const goToNextStep = () => {
          const nextStep = SWITCH_TYPE_WIZARD_STEP.migrateMakeChanges;
          handleUpdateSwitchType({ initStep: nextStep });
          setCurrentStep(nextStep);
     };

     return (
          <SwitchTypeWizardBody showCancelProcess={true} onBackBtnClicked={onBackBtnClicked}>
               <CCard className='migrate'>
                    <CCardBody>
                         <h3>Migrate Your Data Actions</h3>
                         <p className='des'>
                              We'll convert your existing Data Actions in ListenLayer to utilize {isGTM ? 'Client' : 'Server'} Side tagging. This process will happen
                              automatically on the next screen. After it is complete,{' '}
                              {isGTM ? `you'll need to send your configuration to GTM.` : `you'll be provided options for how to clean up your old tags.`}
                         </p>
                         <CButton className='btn-save' type='submit' style={{ width: '40%' }} onClick={goToNextStep}>
                              <Waiting>CHANGE DATA ACTIONS</Waiting>
                         </CButton>
                    </CCardBody>
               </CCard>
          </SwitchTypeWizardBody>
     );
};

export default Migrate;
