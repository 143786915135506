import CIcon from '@coreui/icons-react';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { debounce } from 'lodash';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFlexibleMultiModal } from '../../../../actions/common';
import { setWidthColumnReports } from '../../../../actions/subscriber';
import {
     COMPONENT_NAME,
     DEFAULT_DATE_FORMAT,
     REPORT_DATA_TYPES,
     REPORT_NAMES,
     REPORT_TAB_TYPES,
     REPORT_TYPE,
     SUBSCRIBER_PATH,
     VARIABLE_OPERATORS_DIMENSION_REPORT,
} from '../../../../constants';
import { useDidMount, usePrevious } from '../../../../helpers/customHooks';
import { encodeObj } from '../../../../utils';
import {
     filterConversionNameItem,
     findItemByTargetId,
     findLongestValue,
     formatNumber,
     handleChangeCol,
     handleChangeColSort,
} from '../../../../utils/UtilReport';
import { EventExplorerContext } from './EventExplorer';
import ReportTableCompare from './ReportTableCompare';
import {
     ActiveUser,
     ActiveUserCount,
     ChannelPath,
     ConversionValue,
     CustomConversionValue,
     EventCount,
     Industries,
     PeopleCount,
     PersonCreated,
     ReportDuration,
     RevealedCompany,
     SecondaryConversionValueCount,
     SessionCount,
     TableCellDefault,
     TableCellValueUndefined,
     Tag,
     UserCount,
     UserSourceDetails,
     ViewDetail,
     ViewProfileById,
} from './report-v2/FieldCustom';
import { ChangeDataLoading, LoadingPanel, LongLoading, ReportIsCancel } from './report-v2/LoadingTemplate';
import TooltipContentTemplate from './report-v2/TooltipContentTemplate';
import UpgradePlanText from './report-v2/UpgradePlanText';
import { CustomFooterCellDefault, CustomFooterCellDimensions, CustomFooterCellProperties } from './report-v2/grid/CustomFooterCell';
import CustomHeaderCell from './report-v2/grid/CustomHeaderCell';
import PaginationReport from './report-v2/grid/PaginationReport';
import useFetchData from './report-v2/useFetchData';

const ReportTable = () => {
     const dispatch = useDispatch();
     const {
          reportName,
          filterMetric,
          setFilterMetric,
          filterDimensions,
          setFilterDimensions,
          filterProperties,
          setFilterProperties,
          reportData,
          filterEvent,
          setIsLoading,
          isLoading,
          tableData,
          setTableData,
          sort,
          setSort,
          itemsPerPage,
          setItemsPerPage,
          scoped,
          reportType,
          conversionNameMetric,
          handleGetOption,
          reportCompare,
          showData,
          enableFullAnalytics,
          enableRevealedCompanies,
          changeData,
          recordProfile,
          newProperties: propertiesAll,
          metricsStepForm,
          customProperties,
          dateRangeConfig,
          metricDefault,
          setMetricDefault,
          convRate,
          convName,
          setConvName,
          weight,
          // setFirstChangeData,
          eCommerce,
          setECommerce,
          utmArray,
          eCoItemCategories,
          // setAttributeBeforeReload,
          setChangeData,
          setOffSet,
          handleFindView
     } = useContext(EventExplorerContext);
     let { dimensions, metrics } = reportType ? REPORT_DATA_TYPES[reportName][scoped] : REPORT_DATA_TYPES[reportName];
     if (reportName === REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT) {
          dimensions = [...dimensions, ...utmArray];
          // metrics = handleGetOption({ option: metrics, type: 'weight', reportName, weight });
     }
     if (reportName === REPORT_NAMES.ECOMMERCE_ITEMS) {
          dimensions = [...dimensions, ...eCoItemCategories];
     }

     const dateRangeReports = useSelector((state) => state.subscriber.dateRangeReports);
     const widthColumnReports = useSelector((state) => state.subscriber.widthColumnReports);
     const reloadReportData = useSelector((state) => state.subscriber.reloadReportData);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const [reloadTable, setReloadTable] = useState(false);
     const newDimensions = handleChangeCol(dimensions, filterDimensions);
     const newProperties = handleChangeCol(propertiesAll, filterProperties);
     const defaultShowShortId = dimensions ? dimensions.map((item) => item.key) : [];
     const [newMetric, setNewMetric] = useState(handleChangeColSort([...conversionNameMetric, ...metrics], filterMetric));
     const [activePage, setActivePage] = useState(1);
     const totalPages = Math.ceil(reportData.overallRowCount / itemsPerPage) || 1;
     const didMount = useDidMount();
     const [pagination, setPagination] = useState(true);
     const showLock = {};
     const [lock, setLock] = useState({});
     // const [colLockCount, setColLockCount] = useState(filterDimensions.length + filter.length);
     const tableGrid = useRef(null);
     // const [stickyFooterTotal, setStickyFooterTotal] = useState(false);

     const [showLongLoading, setShowLongLoading] = useState(false);
     const [isLoadingFetch, setIsLoadingFetch] = useState(false);
     const [reportIsCancel, setReportIsCancel] = useState(false);
     const [showShortId, setShowShortId] = useState(defaultShowShortId);
     const arrShowShortIds = ['personId', 'userId', 'sessionId', 'eventId', 'eventECommerceId', 'companyId', 'formId', 'formHostNamePathName'];
     // const listReportShowTooltipUserSource = ['conversionEvents', 'sessionDetails', 'userDetails', 'personDetails', 'companyDetails', 'formCategoryReport']
     //Tooltip
     const [openTooltip, setOpenTooltip] = useState(false);
     const [targetElement, setTargetElement] = useState(null);
     const [positionTooltip, setPositionTooltip] = useState('right');
     // const hiddenTemp = [REPORT_NAMES.SESSION_DETAILS, REPORT_NAMES.LANDING_PAGE_REPORT].includes(reportName)
     const [showCopyTooltip, setShowCopyTooltip] = useState(true);
     const isHiddenCopy = true;
     const disableSort = reportName === REPORT_NAMES.ECOMMERCE_PURCHASE_FLOW;
     const previousActivePage = usePrevious(activePage);
     const previousReloadPage = usePrevious(reloadTable);
     const previousNewProperties = usePrevious(newProperties);
     const previousNewDimensions = usePrevious(newDimensions);
     const previousFilterMetric = usePrevious(filterMetric);
     let returnToPage1 = activePage !== 1 && activePage === previousActivePage && reloadTable === previousReloadPage;
     const finalActivePage = returnToPage1 ? 1 : activePage;

     const inputFetchData = {
          setIsLoading,
          itemsPerPage,
          activePage,
          setActivePage,
          reloadTable,
          sort,
          setShowLongLoading,
          returnToPage1,
          finalActivePage,
          pagination,
          dateRangeConfig,
          reloadReportData,
          setIsLoadingFetch,
          setReportIsCancel,
     };

     useFetchData(inputFetchData);

     useEffect(() => {
          if (metricsStepForm.length > 0) {
               setNewMetric(
                    handleChangeColSort(
                         [...conversionNameMetric, ...metrics, ...metricsStepForm],
                         [...filterMetric, ...handleGetOption({ option: metricsStepForm })]
                    )
               );
          }
     }, [metricsStepForm]); //eslint-disable-line

     useEffect(() => {
          setNewMetric(handleChangeColSort([...conversionNameMetric, ...metrics], filterMetric));
     }, [weight]); //eslint-disable-line

     function getWidthByKey(data, reportType, reportName, label, key, tableData, index) {
          const longestValue = tableData ? findLongestValue(tableData) : undefined;
          if (data[reportType] && data[reportType][reportName] && data[reportType][reportName][label] !== undefined) {
               return data[reportType][reportName][label];
          }
          let widthDefault = label.length * 7.2 + 65;

          if (longestValue && longestValue[key] && longestValue[key].length * 6 + 50 > widthDefault) {
               return Math.min(
                    575,
                    (index === 0 || index === 1) && arrShowShortIds.includes(key) && showShortId.includes(key)
                         ? label.length * 7.7 + 70
                         : longestValue[key].length * 7 + 50
               );
          }

          if (reportName === REPORT_NAMES.ECOMMERCE_PURCHASE_TRANSACTION && key === 'userSourcePath') {
               return widthDefault + 135;
          }

          return widthDefault;
     }

     // function filterConversionItems(data, scoped) {
     //      const filterDefaultItems = [];
     //      const otherItems = [];
     //      const eCommerceItems = [];

     //      data.forEach((item) => {
     //           if (
     //                item.value.toLowerCase().includes(`${scoped}Count`.toLowerCase()) &&
     //                reportName !== 'allConversionReport' &&
     //                reportName !== REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT
     //           ) {
     //                filterDefaultItems.push(item);
     //           } else if (item.label.includes('Conv Rate')) {
     //                filterDefaultItems.push(item);
     //           } else if (item.label.includes('Ecommerce')) {
     //                eCommerceItems.push(item);
     //           } else {
     //                otherItems.push(item);
     //           }
     //      });
     //      return [filterDefaultItems, otherItems, eCommerceItems];
     // }

     //Function tooltip
     const removeElementTooltipScroll = () => {
          if ('ontouchstart' in window || navigator.maxTouchPoints) {
               const el = document.querySelector('[id^="tippy-"]');
               if (el) {
                    el.remove();
               }
          }
     };

     const showTooltip = (element, isHiddenCopy) => {
          isHiddenCopy ? setShowCopyTooltip(false) : setShowCopyTooltip(true);
          setOpenTooltip(true);
          setTargetElement(element.current);
     };

     useEffect(() => {
          const handleOutsideClick = (event) => {
               if (event.target.attributes.getNamedItem('title') === null && event.target.getAttribute('class') !== 'icon-copy') {
                    setOpenTooltip(false);
               }
          };

          const hideTooltip = () => {
               setOpenTooltip(false);
          };

          document.addEventListener('click', handleOutsideClick);
          document.addEventListener('scroll', hideTooltip);

          return () => {
               document.removeEventListener('click', handleOutsideClick);
               document.removeEventListener('scroll', hideTooltip);
          };
     }, []);

     const changePositionTooltip = (props) => {
          let position = 'right';
          let left = props.mouseLeft + 10;
          let top = props.mouseTop - props.element.clientHeight / 2;
          if (props.mouseLeft + 360 > document.body.clientWidth) {
               left = props.mouseLeft - (props.element.clientWidth + 10);
               position = 'left';
          }
          setPositionTooltip(position);
          return { left, top };
     };

     const tableRows = useMemo(() => {
          // Transform reportData.rows into suitable data for CDataTable
          // Each row will be {key1: value1, key2: value2, ...}
          let returnRows = reportData.rows.map((row) =>
               Object.entries(row).reduce((newRow, [compoundKey, compoundValue]) => {
                    let fieldValues = [];
                    let keyCompound;

                    switch (compoundKey) {
                         case 'dimensionCompoundValues':
                              keyCompound = newDimensions;
                              break;
                         case 'metricCompoundValues':
                              keyCompound = newMetric;
                              break;
                         case 'propertyCompoundValues':
                              keyCompound = newProperties;
                              break;
                         default:
                    }
                    // Merge all compoundValues into 1 object
                    fieldValues = compoundValue.reduce((prevVal, currentVal, i) => {
                         if (i <= keyCompound.length - 1) {
                              let personId = {};
                              keyCompound[i].key === 'personCreatedCount' && (personId = { personId: currentVal.personId });
                              return { ...prevVal, [keyCompound[i].key]: currentVal.value, ...personId };
                         } else {
                              return { ...prevVal };
                         }
                    }, {});
                    return { ...newRow, ...fieldValues };
               }, {})
          );
          return returnRows;
     }, [JSON.stringify(reportData)]); // eslint-disable-line

     // const handleOrderCol = (arr, arrFilter) => {
     //      const newArr = [];
     //      arr.forEach((item) => {
     //           if (arrFilter.some((el) => el.value === item.key)) {
     //                newArr.push(item.key);
     //           }
     //      });
     //      return newArr;
     // };
     // useEffect(() => {
     //      setAttributeBeforeReload({
     //           dimensions: handleOrderCol(dimensions, filterDimensions),
     //           properties: handleOrderCol(propertiesAll, filterProperties),
     //           metrics: handleChangeColSort([...conversionNameMetric, ...metrics, ...metricsStepForm], filterMetric).map((i) => i.key),
     //      });
     // }, [tableRows]); // eslint-disable-line
     useEffect(() => {
          const metricReport = [...conversionNameMetric, ...metrics];
          const updateMetric = () => {
               if (reportName === REPORT_NAMES.FORM_CATEGORY_REPORT) {
                    setNewMetric(
                         handleChangeColSort(
                              [...conversionNameMetric, ...metrics, ...metricsStepForm],
                              [...filterMetric, ...handleGetOption({ option: metricsStepForm })]
                         )
                    );
               } else {
                    setNewMetric(handleChangeColSort([...conversionNameMetric, ...metrics], filterMetric));
               }
          };
          if (sort && sort.fieldName) {
               if (filterMetric[0] && !disableSort) {
                    if (!filterMetric.find((el) => el.value === sort.fieldName) && metricReport.find((el) => el.key === sort.fieldName)) {
                         setSort({
                              fieldName: filterMetric[0].value,
                              isDesc: sort && sort.fieldName ? sort.isDesc : true,
                         });
                    } else if (
                         !filterMetric.find((el) => el.value === sort.fieldName) &&
                         [newDimensions, newProperties].find((el) => el.value === sort.fieldName)
                    ) {
                         setSort({
                              fieldName: sort.fieldName,
                              isDesc: sort && sort.fieldName ? sort.isDesc : true,
                         });
                    }

                    // setFirstSort(false)
               } else if (filterMetric.length === 0 && metricReport.find((el) => el.key === sort.fieldName && !disableSort)) {
                    setSort({});
               }
               updateMetric();
          } else if (filterMetric[0] && filterMetric.length === metricReport.length) {
               setSort({
                    fieldName: filterMetric[0].value,
                    isDesc: true,
               });
               updateMetric();
          }
          // eslint-disable-next-line
     }, [filterMetric]);
     useEffect(() => {
          if (filterMetric[0] && !disableSort) {
               setSort({
                    fieldName: filterMetric[0].value,
                    isDesc: true,
               });
          }
          // eslint-disable-next-line
     }, [scoped]);

     useEffect(() => {
          const dataSort = [...filterDimensions, ...filterProperties];
          if (sort && sort.fieldName && !filterMetric.find((_item) => _item.value === sort.fieldName)) {
               if (!dataSort.find((_item) => _item.value === sort.fieldName)) {
                    setSort({});
               }
          }
     }, [filterDimensions, filterProperties]); // eslint-disable-line

     const areEqualShuffled = (arr1 = [], arr2 = []) => {
          if (arr1.length !== arr2.length) {
              return false;
          }
          
          let usedIndices = new Set();
          
          return arr1.every(item1 => {
              return arr2.some((item2, index) => {
                  const isEqual = JSON.stringify(item1) === JSON.stringify(item2);
                  if (isEqual && !usedIndices.has(index)) {
                      usedIndices.add(index);
                      return true;
                  }
                  return false;
              });
          });
     }

     useEffect(() => {
          let checkFetch = false;
          if (filterMetric && previousFilterMetric) {
               checkFetch = filterMetric.some((el) => !previousFilterMetric.find((item) => item.value === el.value));
          }

          if (newProperties && previousNewProperties && !checkFetch) {
               checkFetch = newProperties.some((el) => !previousNewProperties.find((item) => item.key === el.key));
          }

          if (newDimensions && previousNewDimensions && !checkFetch   ) {
               checkFetch = !areEqualShuffled(previousNewDimensions,newDimensions);
          }
          // checkFetch = filterMetric.some((el) => {
          //      const newRow = tableRows[0];
          //      if (newRow) {
          //           return !newRow.hasOwnProperty(el.value);
          //      } else {
          //           return false;
          //      }
          // });

          // if (!checkFetch) {
          //      checkFetch = filterProperties.some((el) => {
          //           const newRow = tableRows[0];
          //           if (newRow) {
          //                return !newRow.hasOwnProperty(el.value);
          //           } else {
          //                return false;
          //           }
          //      });
          //      // if (tableRows[0] && newProperties && previousNewProperties && newProperties.length > previousNewProperties.length) {
          //      //      checkFetch = true;
          //      // }
          // }

          if (checkFetch && didMount) {
               setReloadTable((prev) => !prev);
               if (reportName === REPORT_NAMES.FORM_CATEGORY_REPORT) {
                    setNewMetric(
                         handleChangeColSort(
                              [...conversionNameMetric, ...metrics, ...metricsStepForm],
                              [...filterMetric, ...handleGetOption({ option: metricsStepForm })]
                         )
                    );
               } else {
                    setNewMetric(handleChangeColSort([...conversionNameMetric, ...metrics], filterMetric));
               }
          }
     }, [filterProperties, filterMetric, filterDimensions]); // eslint-disable-line

     // Refresh data when changes dimensions
     useEffect(() => {
          if (didMount) {
               let checkFetch = false;

               if (newDimensions && previousNewDimensions && !checkFetch) {
                    checkFetch = newDimensions.some((el) => !previousNewDimensions.find((item) => item.key === el.key));
               }

               if (checkFetch) {
                    setChangeData(true);
                    setReloadTable(!reloadTable);
               }
               // setColLockCount(filterDimensions.length + filterProperties.length);
          }
     }, [filterDimensions, filterDimensions.length, scoped]); // eslint-disable-line react-hooks/exhaustive-deps

     const onPaginationChange = (i) => {
          setOffSet((i - 1) * itemsPerPage)
          setActivePage(i);
     };

     useEffect(() => {
          const observeCallback = (mutationsList) => {
               mutationsList.forEach((mutation) => {
                    if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
                         // Handle the style change here
                         mutation.target.removeAttribute('style');
                    }
               });
          };

          const observeElements = () => {
               const observer = new MutationObserver(observeCallback);
               const elements = document.querySelectorAll('.k-table');
               elements.forEach((element) => {
                    observer.observe(element, {
                         attributeFilter: ['style'],
                         attributeOldValue: true,
                    });
               });

               // Clean up the observer when the component unmounts
               return () => {
                    observer.disconnect();
               };
          };

          observeElements();
     }, []);

     const handleReturnPage = () => {
          tableGrid.current.containerRef.current.scrollTo({ top: 0 });
          const table = tableGrid.current._element.querySelectorAll('.k-table');
          table.forEach((item) => {
               item.removeAttribute('style');
          });
          setActivePage(1);
          setOffSet(0);
     };
     useEffect(() => {
          handleReturnPage();
          // if (countTrueItems && stickyFooterTotal) {
          //      const element = document.querySelectorAll('tfoot.k-table-tfoot td.k-table-td');
          //      if (element[0]) {
          //           element[0].classList.add('k-grid-footer-sticky');
          //      }
          // }
     }, [filterEvent, sort, dateRangeReports]); // eslint-disable-line react-hooks/exhaustive-deps
     useEffect(() => {
          if (
               (newProperties && previousNewProperties && newProperties.length > previousNewProperties.length) ||
               (filterMetric && previousFilterMetric && filterMetric.length > previousFilterMetric.length)
          ) {
               handleReturnPage();
          }
     }, [filterProperties, filterMetric]); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          if (!pagination) {
               if (activePage === 1) setTableData([...tableRows]);
               else setTableData((old) => [...old, ...tableRows]);
          } else {
               setTableData([...tableRows]);
          }
          // if (countTrueItems && stickyFooterTotal) {
          //      const element = document.querySelectorAll('tfoot.k-table-tfoot td.k-table-td');
          //      if (element[0]) {
          //           element[0].classList.add('k-grid-footer-sticky');
          //      }
          // }
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [tableRows, pagination, scoped]);

     useEffect(() => {
          // if (reportType === 'Conversions') {
          //      const [filterDefaultItems, otherItems, eCommerceItems] = filterConversionItems(filterMetric, scoped);
          //      setMetricDefault(otherItems);
          //      setConvRate(filterDefaultItems);
          //      setECommerce(eCommerceItems);
          // } else {
          const conversionMetric = handleGetOption({ option: conversionNameMetric });
          const [filterDefaultItems, otherItems, eCommerceItems] = filterConversionNameItem(filterMetric, conversionMetric);
          if (
               [
                    REPORT_TYPE.ATTRIBUTES,
                    REPORT_TYPE.ACQUISITION,
                    REPORT_TYPE.CONTENT,
                    REPORT_TYPE.ENGAGEMENT,
                    REPORT_TYPE.CONVERSIONS,
                    REPORT_TYPE.ECOMMERCE,
               ].includes(reportType) ||
               !reportType
          ) {
               setMetricDefault(filterDefaultItems);
               setConvName(otherItems);
               setECommerce(eCommerceItems);
          }
          // }
          // eslint-disable-next-line
     }, [scoped, filterMetric]);

     const handleColumnResize = (props) => {
          const foundItem = findItemByTargetId(props.columns, props.targetColumnId);
          if (foundItem && props.end) {
               dispatch(
                    setWidthColumnReports(
                         Object.assign({}, widthColumnReports, {
                              [reportType]: Object.assign({}, widthColumnReports[reportType], {
                                   [reportName]: Object.assign(
                                        {},
                                        widthColumnReports[reportType] && widthColumnReports[reportType][reportName]
                                             ? widthColumnReports[reportType][reportName]
                                             : {},
                                        {
                                             [foundItem.title]: foundItem.width,
                                        }
                                   ),
                              }),
                         })
                    )
               );
          }
     };
     const handleScrollTable = (event) => {
          const e = event.nativeEvent;
          if (e.target.scrollTop + 20 >= e.target.scrollHeight - e.target.clientHeight) {
               if (activePage < totalPages) {
                    setActivePage((activePageOld) => ++activePageOld);
               }
          }
     };

     const debouncedHandleScrollTable = debounce((event) => {
          handleScrollTable(event);
     }, 100);

     const handleChangePagination = () => {
          setPagination(!pagination);
          setItemsPerPage(20);
          setTableData([]);
          if (pagination) setActivePage(1);
          setOffSet(0);
     };

     // const handleClickShowLock = (field) => {
     //      if (showLock[field]) {
     //           setShowLock({ [field]: !showLock[field] });
     //      } else {
     //           setShowLock({ [field]: true });
     //      }
     // };

     useEffect(() => {
          const tableHeader = tableGrid.current.headerElementRef.current;
          const header = tableHeader.querySelectorAll('.k-header');
          header.forEach((item) => {
               item.addEventListener('keypress', (e) => {
                    if (e.code === 'Enter') e.currentTarget.querySelector('.table-head').click();
               });
          });
     }, []);

     const onChangeItemsPerPage = (e) => {
          setActivePage(1);
          setOffSet(0);
          setItemsPerPage(+e.target.value);
     };

     const SkeletonLoader = () => {
          const colNum = filterProperties.length + filterDimensions.length + filterMetric.length;
          return (
               <tr>
                    <td colSpan={colNum}>{/* <Skeleton shape={'text'} style={{ width: '100%' }} /> */}</td>
               </tr>
          );
     };

     const handleLinkToOtherReport = (fieldKey, linkedData) => {
          let path = '';
          let linkedReportData;
          let filters = [];

          const {
               id,
               conversionId,
               landingPage,
               exitPage,
               userSourceFirst,
               userSourceLast,
               userSource,
               conversionName,
               userId,
               personId,
               sessionId,
               companyId,
          } = linkedData;
          switch (fieldKey) {
               case 'eventCount':
                    path = SUBSCRIBER_PATH.INSIGHTS_EVENTS;
                    if (reportName === REPORT_NAMES.COMPANY_DETAILS) {
                         filters.push({
                              type: 'companyId',
                              value: companyId,
                              operator: 'ct',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              clickable: true,
                              scoped: scoped,
                              reportName: reportName,
                         });
                         break;
                    }
                    if (reportName === REPORT_NAMES.SESSION_DETAILS) {
                         filters.push({
                              type: 'sessionId',
                              value: sessionId,
                              operator: 'eq',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              clickable: true,
                         });
                         break;
                    }
                    if (reportName === REPORT_NAMES.USER_DETAILS) {
                         filters.push({
                              type: 'userId',
                              value: userId,
                              operator: 'eq',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              clickable: true,
                         });
                         break;
                    }
                    if (reportName === REPORT_NAMES.PERSON_DETAILS || reportName === REPORT_NAMES.PERSON_DETAILS_PROFILE) {
                         filters.push({
                              type: 'personId',
                              value: personId,
                              operator: 'eq',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              clickable: true,
                         });
                         break;
                    }
                    if (reportName === REPORT_NAMES.CONVERSION_DETAILS) {
                         filters.push({
                              type: 'conversionId',
                              value: conversionId,
                              operator: 'eq',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                         });
                    }

                    break;
               case 'personCreatedCount':
                    path = SUBSCRIBER_PATH.INSIGHTS_PEOPLE;
                    filters.push({
                         type: 'personId',
                         value: id,
                         operator: 'eq',
                         scoped: scoped,
                         optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                    });

                    break;
               case 'sessionCount':
                    path = SUBSCRIBER_PATH.INSIGHTS_SESSIONS;
                    if (reportName === REPORT_NAMES.USER_DETAILS) {
                         filters.push({
                              type: 'userId',
                              value: userId,
                              operator: 'eq',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              scoped: scoped,
                              reportName: reportName,
                              clickable: true,
                         });
                         break;
                    }
                    if (reportName === REPORT_NAMES.PERSON_DETAILS || reportName === REPORT_NAMES.PERSON_DETAILS_PROFILE) {
                         filters.push({
                              type: 'personId',
                              value: personId,
                              operator: 'eq',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              scoped: scoped,
                              reportName: reportName,
                              clickable: true,
                         });
                         break;
                    }
                    newDimensions.forEach((item, index) => {
                         filters.push({
                              type: item.key,
                              value: `${linkedData[item.key]}`,
                              operator: 'eq',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              scoped: scoped,
                              reportName: reportName,
                              clickable: true,
                              dateRange: {
                                   startDate: dateRangeReports.selection.startDate.format(DEFAULT_DATE_FORMAT),
                                   endDate: dateRangeReports.selection.endDate.format(DEFAULT_DATE_FORMAT),
                              },
                              utmArray: utmArray.filter((el) => el.key === item.key),
                              weight: weight,
                         });
                    });

                    break;

               case 'userCount':
                    path = SUBSCRIBER_PATH.INSIGHTS_USERS;
                    newDimensions.forEach((item, index) => {
                         filters.push({
                              type: item.key,
                              value: `${linkedData[item.key]}`,
                              operator: 'eq',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              scoped: scoped,
                              reportName: reportName,
                              clickable: true,
                         });
                    });

                    break;

               case 'revealedCompany':
                    path = SUBSCRIBER_PATH.INSIGHTS_REVEALED_COMPANIES;
                    newDimensions.forEach((item, index) => {
                         filters.push({
                              type: item.key,
                              value: `${linkedData[item.key]}`,
                              operator: 'eq',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              scoped: scoped,
                              reportName: reportName,
                              clickable: true,
                         });
                    });

                    break;

               case 'peopleCount':
                    path = SUBSCRIBER_PATH.INSIGHTS_PEOPLE;
                    newDimensions.forEach((item, index) => {
                         filters.push({
                              type: item.key,
                              value: `${linkedData[item.key]}`,
                              operator: 'eq',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              scoped: scoped,
                              reportName: reportName,
                              clickable: true,
                         });
                    });

                    break;

               case 'activeUser':
                    path = SUBSCRIBER_PATH.INSIGHTS_USERS;

                    if (landingPage) {
                         filters.push({
                              type: 'landingPage',
                              value: landingPage,
                              operator: 'eq',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                         });
                    }

                    if (exitPage) {
                         filters.push({
                              type: 'exitPage',
                              value: exitPage,
                              operator: 'eq',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                         });
                    }

                    break;
               case 'activeUserCount':
                    path = SUBSCRIBER_PATH.INSIGHTS_USERS;

                    if (userSourceFirst) {
                         filters.push({
                              type: 'userSourceFirst',
                              value: userSourceFirst,
                              operator: 'eq',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                         });
                    }

                    if (userSourceLast) {
                         filters.push({
                              type: 'userSourceLast',
                              value: userSourceLast,
                              operator: 'eq',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                         });
                    }

                    if (userSource) {
                         filters.push({
                              type: 'userSourceSession',
                              value: userSource,
                              operator: 'eq',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                         });
                    }
                    if (conversionName) {
                         filters.push({
                              type: 'conversionName',
                              value: conversionName,
                              operator: 'eq',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                         });
                    }
                    break;
               default:
          }
          linkedReportData = {
               filters,
               dateRange: {
                    startDate: dateRangeReports.selection.startDate.format(DEFAULT_DATE_FORMAT),
                    endDate: dateRangeReports.selection.endDate.format(DEFAULT_DATE_FORMAT),
               },
               dateScope: dateRangeConfig.dateScope,
          };

          path = path.replace(':secondId', activeAccount.secondId) + '?data=' + encodeObj(linkedReportData);
          window.open(path, '_blank');
     };

     const onClickShowDetailUser = (linkedData) => {
          dispatch(
               setFlexibleMultiModal({
                    show: true,
                    attributes: { id: linkedData.userId, data: linkedData },
                    component: COMPONENT_NAME.RECORD_PROFILE_USER,
               })
          );
     };

     const onClickShowDetailEvent = (linkedData) => {
          dispatch(
               setFlexibleMultiModal({
                    show: true,
                    attributes: { id: linkedData.eventId },
                    component: COMPONENT_NAME.RECORD_PROFILE_EVENT,
               })
          );
     };

     const onClickShowDetailEventECommerce = (linkedData) => {
          dispatch(
               setFlexibleMultiModal({
                    show: true,
                    attributes: { id: linkedData.eventECommerceId },
                    component: COMPONENT_NAME.RECORD_PROFILE_EVENT_E_COMMERCE,
               })
          );
     };

     const onClickShowDetailSession = (linkedData) => {
          dispatch(
               setFlexibleMultiModal({
                    show: true,
                    attributes: { id: linkedData.sessionId, data: linkedData },
                    component: COMPONENT_NAME.RECORD_PROFILE_SESSION,
               })
          );
     };

     const onClickShowDetailPerson = (linkedData) => {
          dispatch(
               setFlexibleMultiModal({
                    show: true,
                    attributes: { id: linkedData.personId, data: linkedData },
                    component: COMPONENT_NAME.RECORD_PROFILE_PERSON,
               })
          );
     };
     const onClickShowDetailCompany = (linkedData) => {
          dispatch(
               setFlexibleMultiModal({
                    show: true,
                    attributes: { id: linkedData.companyId, data: linkedData },
                    component: COMPONENT_NAME.RECORD_PROFILE_COMPANY,
               })
          );
     };

     const onClickShowDetailForm = (linkedData) => {
          dispatch(
               setFlexibleMultiModal({
                    show: true,
                    attributes: { id: linkedData.formId, locationForm: linkedData.formHostNamePathName || null },
                    component: COMPONENT_NAME.RECORD_PROFILE_FORM,
               })
          );
     };

     const handleEnterToLink = (e) => {
          if (e.which === 13) e.currentTarget.click();
     };

     function CustomCell(props, showTooltip) {
          const refCell = useRef(null);
          const value = props.dataItem[props.field];
          const { style, className, id } = props;

          if (value === undefined) {
               return TableCellValueUndefined(isLoading);
          }

          if (!enableFullAnalytics && !showData && (props.dataIndex > 4 || tableData.length < 5)) {
               let blur = 4;
               if (tableData.length < 5) {
                    blur = -1;
               }
               const currentIndex = props.dataIndex < 8 ? props.dataIndex : 8;
               switch (props.field) {
                    case 'userSourceFirst':
                    case 'userSourceLast':
                    case 'userSourceFirstPersonCreated':
                    case 'userSourceLastPersonCreated':
                    case 'userSource':
                         return (
                              <td className="view-detail list-tags" style={{ filter: `blur(${currentIndex - blur}px)` }}>
                                   <div className="tags-wrapper">
                                        <Tag item={value.name}></Tag>
                                   </div>
                              </td>
                         );

                    case 'userSourcePath':
                    case 'noneDirectSourcePath':
                    case 'userSourcePathPersonCreated':
                         return (
                              <td className="view-detail list-tags" style={{ filter: `blur(${currentIndex - blur}px)` }}>
                                   <div style={{ display: 'flex', gap: '5px' }}>
                                        {value &&
                                             value.map((item, key) => (
                                                  <div className="tags-wrapper" key={key}>
                                                       <Tag item={item.name}></Tag>
                                                  </div>
                                             ))}
                                   </div>
                              </td>
                         );

                    case 'industries':
                         const industries = props.dataItem.industries;
                         const newIndustries = industries ? industries.split(',') : [];

                         if (industries.toLowerCase() === 'none') {
                              return (
                                   <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                                        <span>{value}</span>
                                   </td>
                              );
                         }
                         return (
                              <td
                                   {...{ style, className }}
                                   className={`view-detail list-tags ${className}`}
                                   style={{ filter: `blur(${currentIndex - blur}px)` }}
                              >
                                   <div className="tags-wrapper">
                                        {newIndustries.map((item) => (
                                             <Tag item={item} />
                                        ))}
                                   </div>
                              </td>
                         );

                    default:
                         break;
               }

               return <td style={{ filter: `blur(${currentIndex - blur}px)` }}>{formatNumber(value)} </td>;
          }

          if (reportName === REPORT_NAMES.COMPANY_DETAILS || reportName === REPORT_NAMES.PEOPLE_AND_USER_COUNT_REPORT) {
               if (!enableRevealedCompanies && (props.dataIndex > 4 || tableData.length < 5)) {
                    let blur = 4;
                    if (tableData.length < 5) {
                         blur = -1;
                    }
                    const currentIndex = props.dataIndex < 8 ? props.dataIndex : 8;
                    switch (props.field) {
                         case 'industries':
                              const industries = props.dataItem.industries;
                              const newIndustries = industries ? industries.split(',') : [];

                              if (industries.toLowerCase() === 'none') {
                                   return (
                                        <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                                             <span>{value}</span>
                                        </td>
                                   );
                              }
                              return (
                                   <td
                                        {...{ style, className }}
                                        className={`view-detail list-tags ${className}`}
                                        style={{ filter: `blur(${currentIndex - blur}px)` }}
                                   >
                                        <div className="tags-wrapper">
                                             {newIndustries.map((item) => (
                                                  <Tag item={item} />
                                             ))}
                                        </div>
                                   </td>
                              );

                         case 'userSourceFirst':
                         case 'userSourceLast':
                         case 'userSourceFirstPersonCreated':
                         case 'userSourceLastPersonCreated':
                         case 'userSource':
                              return (
                                   <td className={`view-detail list-tags ${className}`} style={{ filter: `blur(${currentIndex - blur}px)` }}>
                                        <div className="tags-wrapper">
                                             <Tag item={value.name}></Tag>
                                        </div>
                                   </td>
                              );

                         case 'userSourcePath':
                         case 'noneDirectSourcePath':
                         case 'userSourcePathPersonCreated':
                              return (
                                   <td className={`view-detail list-tags ${className}`} style={{ filter: `blur(${currentIndex - blur}px)` }}>
                                        <div style={{ display: 'flex', gap: '5px' }}>
                                             {value &&
                                                  value.map((item, key) => (
                                                       <div className="tags-wrapper" key={key}>
                                                            <Tag item={item.name}></Tag>
                                                       </div>
                                                  ))}
                                        </div>
                                   </td>
                              );

                         default:
                              break;
                    }

                    return <td style={{ filter: `blur(${currentIndex - blur}px)` }}>{formatNumber(value)} </td>;
               }
          }

          if (props.field.includes('Rate')) {
               return <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>{`${formatNumber(value)}%`}</td>;
          }

          if (
               (props.columnIndex === 0 && newDimensions[0] && arrShowShortIds.includes(newDimensions[0].key)) ||
               (props.columnIndex === 1 && newDimensions[1] && arrShowShortIds.includes(newDimensions[1].key))
          ) {
               return (
                    <td
                         ref={refCell}
                         onClick={value !== 'undefine' ? () => showTooltip(refCell) : null}
                         {...{ className }}
                         style={{ ...style, textAlign: 'center', cursor: value !== 'undefine' ? 'pointer' : 'default' }}
                         tabIndex={0}
                         data-keyboardnavid={id}
                         title={' '}
                         data-title={value}
                    >
                         {showShortId.includes(props.field) ? (
                              <i style={{ pointerEvents: 'none' }}>
                                   <CIcon name="iconFingerprint" style={{ color: '#63c2de' }} />
                              </i>
                         ) : (
                              <span style={{ pointerEvents: 'none' }}>{value}</span>
                         )}
                    </td>
               );
          }

          const customConversionValueProps =
               customProperties &&
               customProperties.find((item) => item.properties === props.field && item.pathPropertyName === 'conversion.conversionValue');
          const conversionNameValueProps =
               conversionNameMetric && conversionNameMetric.find((item) => item.key.includes('convValue') && item.key === props.field);

          switch (props.field) {
               case 'avgSessionDuration':
               case 'sessionDuration':
                    return ReportDuration(value, props);
               case 'avgVisibleTimeOnPage':
                    return ReportDuration(value ? value : 0, props);
               case 'avgVisibleTimePerPage':
                    return ReportDuration(value, props);
               case 'sessionCount':
                    return SessionCount(props.dataItem, props, handleEnterToLink, handleLinkToOtherReport);

               case 'userCount':
                    return UserCount(props.dataItem, props, handleEnterToLink, handleLinkToOtherReport);

               case 'revealedCompany':
                    return RevealedCompany(props.dataItem, props, handleEnterToLink, handleLinkToOtherReport);

               case 'peopleCount':
                    return PeopleCount(props.dataItem, props, handleEnterToLink, handleLinkToOtherReport);

               case 'eventCount':
                    return EventCount(props.dataItem, props, reportName);

               case 'personCreatedCount':
                    return PersonCreated(props.dataItem, props, handleEnterToLink, handleLinkToOtherReport);

               case 'activeUser':
                    return ActiveUser(props.dataItem, props, handleEnterToLink, handleLinkToOtherReport);

               case 'activeUserCount':
                    return ActiveUserCount(props.dataItem, props, handleEnterToLink, handleLinkToOtherReport, reportName);

               case 'viewDetail':
                    return ViewDetail(
                         props.dataItem,
                         props,
                         filterDimensions,
                         onClickShowDetailEvent,
                         onClickShowDetailEventECommerce,
                         onClickShowDetailUser,
                         onClickShowDetailSession,
                         onClickShowDetailPerson,
                         onClickShowDetailForm,
                         onClickShowDetailCompany
                    );

               case 'sessionId':
               case 'userId':
               case 'personId':
               case 'formId':
               case 'companyId':
                    return ViewProfileById(
                         props.dataItem,
                         props,
                         filterDimensions,
                         onClickShowDetailUser,
                         onClickShowDetailSession,
                         onClickShowDetailPerson,
                         onClickShowDetailForm,
                         onClickShowDetailCompany,
                         props.field
                    );
               case 'userSourceFirst':
               case 'userSourceLast':
               case 'noneDirectSourcePath':
               case 'userSourceFirstPersonCreated':
               case 'userSourcePathPersonCreated':
               case 'userSourceLastPersonCreated':
               case 'userSource':
                    return UserSourceDetails(value, props, {}, showTooltip);
               case 'userSourcePath':
                    return UserSourceDetails(value, props, { isPath: true, refCell });
               case 'channelPath':
                    return ChannelPath(value, props, refCell, showTooltip);
               case 'industries':
                    return Industries(value, props);
               case 'priceCustom':
               case 'conversionValue':
                    return ConversionValue(value, props);
               case `${customConversionValueProps}`:
                    return CustomConversionValue(value, props);
               case 'engagementDriven':
               case 'firstTouch':
               case 'lastTouch':
               case 'linear':
               case 'uShape':
               case 'conversionValueCount':
               case 'conversionCount':
               case 'primaryConversionValueCount':
               case 'primaryConversionCount':
               case 'secondaryConversionCount':
               case `${conversionNameValueProps ? conversionNameValueProps.key : ``}`:
               case 'secondaryConversionValueCount':
                    return SecondaryConversionValueCount(value, props);
               case 'itemId':
               case 'transactionId':
               case 'phone':
               case 'utmContent':
               case 'utmCampaign':
               case 'utmTerm':
               case 'utmSource':
               case 'utmMedium':
               case 'postalCodeCustom':
                    return (
                         <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                              <span>{String(value)}</span>
                         </td>
                    );
               default:
                    return TableCellDefault(value, props, refCell, showTooltip);
          }
     }

     const propsCompare = {
          getWidthByKey,
          metricDefault,
          convName,
          eCommerce,
          convRate,
          tableGrid,
          handleColumnResize,
          SkeletonLoader,
          lock,
          activePage,
          setActivePage,
          arrShowShortIds,
          showLock,
          showShortId,
          sort,
          disableSort,
          setLock,
          setShowShortId,
     };

     const shouldDisplayUpgradePlanText = () => {
          return (
               (!enableFullAnalytics && !showData && !isLoading) ||
               ((reportName === REPORT_NAMES.COMPANY_DETAILS || reportName === REPORT_NAMES.PEOPLE_AND_USER_COUNT_REPORT) &&
                    !enableRevealedCompanies &&
                    !isLoading)
          );
     };

     const mergeMetrics = useMemo(() => [...filterMetric], [filterMetric]);

     const handleColumnReorder = ({ columns, columnId }) => {
          columns.forEach((column) => {
               column.children.some((child) => {
                    if (child.id === columnId) {
                         const sortedChildren = column.children.sort((a, b) => a.orderIndex - b.orderIndex);

                         const reorderedColumns = sortedChildren.map((child) => ({
                              label: child.title,
                              value: child.field,
                         }));

                         switch (column.title) {
                              case 'Dimensions':
                                   setFilterDimensions(reorderedColumns);
                                   handleFindView(REPORT_TAB_TYPES.DIMENSIONS, reorderedColumns);
                                   break;
                              case 'Properties':
                                   setFilterProperties(reorderedColumns);
                                   handleFindView(REPORT_TAB_TYPES.PROPERTIES, reorderedColumns);
                                   break;
                              case 'Metrics':
                                   setFilterMetric(reorderedColumns);
                                   setNewMetric(handleChangeColSort([...conversionNameMetric, ...metrics], reorderedColumns));
                                   handleFindView(REPORT_TAB_TYPES.METRICS, reorderedColumns);
                                   break;
                              default:
                                   break;
                         }

                         return true;
                    }

                    return false;
               });
          });
     };

     return (
          <div className={`report-table ${!enableFullAnalytics && !showData ? 'no-action' : ''}`}>
               {isLoading && pagination && <LoadingPanel />}
               {reportIsCancel && <ReportIsCancel setReportIsCancel={setReportIsCancel} setReloadTable={setReloadTable} />}
               {isLoadingFetch && showLongLoading && <LongLoading />}
               {changeData && tableData.length !== 0 && <ChangeDataLoading />}
               {shouldDisplayUpgradePlanText() && <UpgradePlanText tableData={tableData} />}

               {!reportCompare.rows && (
                    <>
                         <Tooltip
                              className={`${recordProfile ? 'tooltip-profile' : ''}`}
                              onPosition={changePositionTooltip}
                              tooltipStyle={{ background: '#050505' }}
                              content={(props) => <TooltipContentTemplate props={props} showCopyTooltip={showCopyTooltip} />}
                              openDelay={0}
                              open={openTooltip}
                              targetElement={targetElement}
                              position={positionTooltip}
                         >
                              <Grid
                                   ref={tableGrid}
                                   style={{ width: 'fit-content', maxWidth: '100%', maxHeight: pagination ? '2500px' : '800px' }}
                                   data={tableData.length === 0 && isLoading ? Array(5).fill({}) : tableData}
                                   onScroll={(event) => {
                                        removeElementTooltipScroll();
                                        if (!pagination) {
                                             debouncedHandleScrollTable(event);
                                        }
                                        if (openTooltip) {
                                             setOpenTooltip(false);
                                        }
                                   }}
                                   resizable={true}
                                   reorderable={true}
                                   navigatable={true}
                                   scrollable="scrollable"
                                   onColumnReorder={handleColumnReorder}
                                   onColumnResize={handleColumnResize}
                                   // rowRender={isLoading && SkeletonLoader}
                              >
                                   <GridNoRecords>No results found!</GridNoRecords>

                                   {filterDimensions.length > 0 && (
                                        <GridColumn orderIndex={0} title="Dimensions" resizable={false}>
                                             {filterDimensions.map((item, index) => {
                                                  return (
                                                       <GridColumn
                                                            key={index}
                                                            title={item.label}
                                                            field={item.value}
                                                            orderIndex={index}
                                                            width={getWidthByKey(
                                                                 widthColumnReports,
                                                                 reportType,
                                                                 reportName,
                                                                 item.label,
                                                                 item.value,
                                                                 tableData,
                                                                 index
                                                            )}
                                                            headerCell={(props) => (
                                                                 <CustomHeaderCell
                                                                      props={props}
                                                                      dimensions={dimensions}
                                                                      arrShowShortIds={arrShowShortIds}
                                                                      showLock={showLock}
                                                                      lock={lock}
                                                                      showShortId={showShortId}
                                                                      sort={sort}
                                                                      disableSort={disableSort}
                                                                      setLock={setLock}
                                                                      setShowShortId={setShowShortId}
                                                                 />
                                                            )}
                                                            locked={lock[item.value] ? true : false}
                                                            footerCell={(props) => (
                                                                 <CustomFooterCellDimensions
                                                                      props={props}
                                                                      filterDimensions={filterDimensions}
                                                                      filterProperties={filterProperties}
                                                                 />
                                                            )}
                                                            cell={(props) => CustomCell(props, showTooltip)}
                                                       />
                                                  );
                                             })}
                                        </GridColumn>
                                   )}

                                   {filterProperties.length > 0 && (
                                        <GridColumn orderIndex={1} title="Properties" resizable={false}>
                                             {filterProperties.map((item, index) => {
                                                  return (
                                                       <GridColumn
                                                            key={index}
                                                            title={item.label}
                                                            field={item.value}
                                                            orderIndex={index}
                                                            width={getWidthByKey(widthColumnReports, reportType, reportName, item.label, item.value)}
                                                            headerCell={(props) => (
                                                                 <CustomHeaderCell
                                                                      props={props}
                                                                      dimensions={dimensions}
                                                                      arrShowShortIds={arrShowShortIds}
                                                                      showLock={showLock}
                                                                      lock={lock}
                                                                      showShortId={showShortId}
                                                                      sort={sort}
                                                                      disableSort={disableSort}
                                                                      setLock={setLock}
                                                                      setShowShortId={setShowShortId}
                                                                 />
                                                            )}
                                                            locked={lock[item.value] ? true : false}
                                                            footerCell={(props) => (
                                                                 <CustomFooterCellProperties
                                                                      props={props}
                                                                      filterDimensions={filterDimensions}
                                                                      filterProperties={filterProperties}
                                                                 />
                                                            )}
                                                            cell={(props) => CustomCell(props, showTooltip, isHiddenCopy)}
                                                       />
                                                  );
                                             })}
                                        </GridColumn>
                                   )}

                                   {mergeMetrics.length > 0 && (
                                        <GridColumn orderIndex={2} title="Metrics" resizable={false}>
                                             {mergeMetrics.map((item, index) => {
                                                  return (
                                                       <GridColumn
                                                            key={index}
                                                            title={item.label}
                                                            field={item.value}
                                                            orderIndex={index}
                                                            width={getWidthByKey(widthColumnReports, reportType, reportName, item.label)}
                                                            headerCell={(props) => (
                                                                 <CustomHeaderCell
                                                                      props={props}
                                                                      dimensions={dimensions}
                                                                      arrShowShortIds={arrShowShortIds}
                                                                      showLock={showLock}
                                                                      lock={lock}
                                                                      showShortId={showShortId}
                                                                      sort={sort}
                                                                      disableSort={disableSort}
                                                                      setLock={setLock}
                                                                      setShowShortId={setShowShortId}
                                                                 />
                                                            )}
                                                            locked={lock[item.value] ? true : false}
                                                            footerCell={(props) => <CustomFooterCellDefault props={props} reportData={reportData} />}
                                                            cell={(props) => CustomCell(props, showTooltip)}
                                                       />
                                                  );
                                             })}
                                        </GridColumn>
                                   )}
                                   {/* {convRate.length > 0 && (
                                        <GridColumn orderIndex={3} title={`${scoped} Conv Rates`} resizable={false}>
                                             {convRate.map((item, index) => {
                                                  return (
                                                       <GridColumn
                                                            key={index}
                                                            title={item.label}
                                                            field={item.value}
                                                            width={getWidthByKey(widthColumnReports, reportType, reportName, item.label)}
                                                            headerCell={(props) => (
                                                                 <CustomHeaderCell
                                                                      props={props}
                                                                      dimensions={dimensions}
                                                                      arrShowShortIds={arrShowShortIds}
                                                                      showLock={showLock}
                                                                      lock={lock}
                                                                      showShortId={showShortId}
                                                                      sort={sort}
                                                                      disableSort={disableSort}
                                                                      setLock={setLock}
                                                                      setShowShortId={setShowShortId}
                                                                 />
                                                            )}
                                                            locked={lock[item.value] ? true : false}
                                                            footerCell={(props) => <CustomFooterCellDefault props={props} reportData={reportData} />}
                                                            cell={(props) => CustomCell(props, showTooltip)}
                                                       />
                                                  );
                                             })}
                                        </GridColumn>
                                   )}
                                   {convName.length > 0 && (
                                        <GridColumn orderIndex={3} title={'Individual Conversions'} resizable={false}>
                                             {convName.map((item, index) => {
                                                  return (
                                                       <GridColumn
                                                            key={index}
                                                            title={item.label}
                                                            field={item.value}
                                                            width={getWidthByKey(widthColumnReports, reportType, reportName, item.label)}
                                                            headerCell={(props) => (
                                                                 <CustomHeaderCell
                                                                      props={props}
                                                                      dimensions={dimensions}
                                                                      arrShowShortIds={arrShowShortIds}
                                                                      showLock={showLock}
                                                                      lock={lock}
                                                                      showShortId={showShortId}
                                                                      sort={sort}
                                                                      disableSort={disableSort}
                                                                      setLock={setLock}
                                                                      setShowShortId={setShowShortId}
                                                                 />
                                                            )}
                                                            locked={lock[item.value] ? true : false}
                                                            footerCell={(props) => <CustomFooterCellDefault props={props} reportData={reportData} />}
                                                            cell={(props) => CustomCell(props, showTooltip)}
                                                       />
                                                  );
                                             })}
                                        </GridColumn>
                                   )}
                                   {eCommerce.length > 0 && (
                                        <GridColumn orderIndex={3} title={'eCommerce Events'} resizable={false}>
                                             {eCommerce.map((item, index) => {
                                                  return (
                                                       <GridColumn
                                                            key={index}
                                                            title={item.label}
                                                            field={item.value}
                                                            width={getWidthByKey(widthColumnReports, reportType, reportName, item.label)}
                                                            headerCell={(props) => (
                                                                 <CustomHeaderCell
                                                                      props={props}
                                                                      dimensions={dimensions}
                                                                      arrShowShortIds={arrShowShortIds}
                                                                      showLock={showLock}
                                                                      lock={lock}
                                                                      showShortId={showShortId}
                                                                      sort={sort}
                                                                      disableSort={disableSort}
                                                                      setLock={setLock}
                                                                      setShowShortId={setShowShortId}
                                                                 />
                                                            )}
                                                            locked={lock[item.value] ? true : false}
                                                            footerCell={(props) => <CustomFooterCellDefault props={props} reportData={reportData} />}
                                                            cell={(props) => CustomCell(props, showTooltip)}
                                                       />
                                                  );
                                             })}
                                        </GridColumn>
                                   )} */}
                              </Grid>
                         </Tooltip>
                         {(!recordProfile || (recordProfile && reportData.overallRowCount > 5)) && (
                              <PaginationReport
                                   pagination={pagination}
                                   activePage={activePage}
                                   totalPages={totalPages}
                                   onPaginationChange={onPaginationChange}
                                   onChangeItemsPerPage={onChangeItemsPerPage}
                                   itemsPerPage={itemsPerPage}
                                   handleChangePagination={handleChangePagination}
                              />
                         )}
                    </>
               )}

               {reportCompare.rows && <ReportTableCompare {...propsCompare}></ReportTableCompare>}
          </div>
     );
};

export default React.memo(ReportTable);
