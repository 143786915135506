import React, { useContext } from 'react';
import { CButton, CCard, CCardBody } from '@coreui/react';
import { SwitchTypeWizardBody, SwitchTypeWizardContext } from '../SwitchTypeWizard';
import Waiting from '../../../../../general/Loadings/Waiting';
import { SWITCH_TYPE_WIZARD_STEP } from '../../../../../../constants';

const MigrateSuccess = () => {
     const { setCurrentStep, isGTM, needConnectToGTM, destinationName, nothingObjectTurnOn } = useContext(SwitchTypeWizardContext);

     const goToNextStep = () => {
          if (needConnectToGTM) {
               if (!isGTM && nothingObjectTurnOn) {
                    setCurrentStep(SWITCH_TYPE_WIZARD_STEP.gtmSuccess);
               } else {
                    setCurrentStep(SWITCH_TYPE_WIZARD_STEP.gtmConnection);
               }
          } else {
               setCurrentStep(SWITCH_TYPE_WIZARD_STEP.gtmVerifyChanges);
          }
     };

     return (
          <SwitchTypeWizardBody shouldHideBackBtn={true}>
               <CCard className='migrate-success'>
                    <CCardBody>
                         <h3>Success!</h3>
                         <p className='des'>
                              Your {destinationName} destination is ready to run {isGTM ? 'Client' : 'Server'} Side!
                         </p>
                         <p className='des'>
                              {isGTM
                                   ? `Before changes go into effect, they need to be sent to your Google Tag Manager account. We'll summarize the changes on the next screen.`
                                   : `We need to remove the client-side objects created inside GTM to support ${destinationName}. On the next screen, we will scan and return
                                   a list of objects so you can decide what to do.`}
                         </p>
                         <CButton className='btn-save' type='submit' style={{ width: '40%' }} onClick={goToNextStep}>
                              <Waiting>NEXT</Waiting>
                         </CButton>
                    </CCardBody>
               </CCard>
          </SwitchTypeWizardBody>
     );
};

export default MigrateSuccess;
