import React, { useContext, useEffect } from 'react';
import { CCard, CCardBody, CSpinner } from '@coreui/react';
import { SwitchTypeWizardBody, SwitchTypeWizardContext } from '../SwitchTypeWizard';
import { SWITCH_TYPE_WIZARD_STEP } from '../../../../../../constants';

const MigrateMakeChanges = () => {
     const { isGTM, usingType, setCurrentStep, needConnectToGTM, handleReloadData, handleUpdateSwitchType, nothingObjectTurnOn } = useContext(SwitchTypeWizardContext);

     const goToNextStep = () => {
          setCurrentStep(SWITCH_TYPE_WIZARD_STEP.migrateSuccess);
     };

     const doSuccess = () => {
          goToNextStep();
          handleReloadData();
     };

     const handleFirstLoad = () => {
          let newInitStep = SWITCH_TYPE_WIZARD_STEP.gtmVerifyChanges;

          if (needConnectToGTM) {
               newInitStep = SWITCH_TYPE_WIZARD_STEP.gtmConnection;

               if (!isGTM && nothingObjectTurnOn) {
                    newInitStep = SWITCH_TYPE_WIZARD_STEP.getStarted;
               }
          }

          const data = {
               usingType,
               initStep: newInitStep,
          };

          setTimeout(() => {
               handleUpdateSwitchType(data, () => doSuccess());
          }, 3000);
     };
     useEffect(handleFirstLoad, []);

     return (
          <SwitchTypeWizardBody shouldHideBackBtn={true}>
               <CCard className='migrate-make-changes'>
                    <CCardBody>
                         <h3>Making your changes</h3>
                         <p className='des'>We're making changes to your Data Actions so they'll run {isGTM ? 'Client' : 'Server'} Side. </p>
                         <div className='center-spinner text-center m-3'>
                              <CSpinner color='primary' />
                         </div>
                         <span className='foot-note'>Please be patient; this can take a couple of minutes. This screen will change once the process is complete.</span>
                    </CCardBody>
               </CCard>
          </SwitchTypeWizardBody>
     );
};

export default MigrateMakeChanges;
